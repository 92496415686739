import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Switch,
  Typography
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../hooks/use-auth';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useSettings } from '../../hooks/use-settings';

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { user } = useAuth();
  const { settings, saveSettings } = useSettings();


  const handleLogout = async () => {
    try {
      onClose?.();
      await logout();
      // router.push('/').catch(console.error);
      navigate('/')
      location.reload()
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      keepMounted
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 300 } }}
      transitionDuration={0}
      
      {...other}>
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex',
          background: (theme) => theme.palette.mode === 'dark' ? "#1E2022" : process.env.REACT_APP_PRIMARY_COLOR
        }}
      >
        <Avatar
          src={user.avatar}
          sx={{
            height: 50,
            width: 50
          }}
        >
          <AccountCircleIcon fontSize="small" />
        </Avatar>
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Typography variant="h6"
          sx={{color : "#FFFFFF"}}>
            {user.firstname} {user.lastname}
          </Typography>
          {user.id_service === 0 ? 
              <Typography variant="body2"
              sx={{color : "#D8C691"}}>
                {process.env.REACT_APP_COMPANY_NAME} : Compte Administrateur
              </Typography> 
            : 
              <Typography variant="body2"
              sx={{color : "#D8C691"}}>
                {process.env.REACT_APP_COMPANY_NAME} : Compte Expert
              </Typography>
            }
        </Box>
      </Box>
      <Box>
        <Divider />
        {/* <MenuItem>
          <LightModeIcon/>
          <Switch
            checked={settings.theme === "dark" ? true : false}
            onChange={() => {
              let value = settings.theme === "dark" ? "light" : "dark"
              saveSettings({...settings, theme: value})
            }}
          />
          <DarkModeIcon/>
        </MenuItem> */}
        <Divider />
        <MenuItem
          onClick={handleLogout}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" 
            sx={{ color: (theme) => theme.palette.mode === 'dark' ? '#f3f4f6' : process.env.REACT_APP_PRIMARY_COLOR}} />
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1"
              sx={{ color: (theme) => theme.palette.mode === 'dark' ? '#f3f4f6' : process.env.REACT_APP_PRIMARY_COLOR}}
              >
                Déconnexion
              </Typography>
            )}
          />
        </MenuItem>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
